/* eslint-disable no-unused-vars */
<template>
  <section class="invoice-preview-wrapper">
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="card-title ">
          Salary Disbursment
        </p>
        <!--        <b-link-->
        <!--            variant="outline-info mx-2"-->
        <!--            type="button"-->
        <!--            @click="onActivityLog"-->
        <!--        >-->
        <!--          Activity log-->
        <!--        </b-link>-->
      </div>
      <!-- select & search -->
      <div class="custom-search d-flex row justify-content-center">
        <!-- Session Dropdown -->
        <b-form-group
          label-for="session"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.sessionId"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Session"
            :options="sessionTypes"
            :reduce="(val) => val.id"
          />
        </b-form-group>

        <!-- Payslip month Dropdown -->
        <b-form-group
          label-for="payslip"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.paySlipMonthId"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Month"
            :options="paySlipMonths"
            :reduce="(val) => val.id"
          />
        </b-form-group>

        <!-- Campus Dropdown -->
        <b-form-group
          label-for="campus"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.campusId"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Campus"
            :options="campuses"
            :reduce="(val) => val.id"
          />
        </b-form-group>

        <!-- Employee Type Dropdown -->
        <b-form-group
          label-for="pay-cycle-config-session"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.employeeTypeId"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Employee Type"
            :options="employeeTypes"
            :reduce="(val) => val.id"
          />
        </b-form-group>

        <!-- Depertment Dropdown -->
        <b-form-group
          label-for="designation"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.departmentId"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Depertment"
            :options="departments"
            :reduce="(val) => val.id"
          />
        </b-form-group>
        <!-- Designation Dropdown -->
        <b-form-group
          label-for="designation"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.designationId"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Designation"
            :options="designations"
            :reduce="(val) => val.id"
          />
        </b-form-group>

        <!-- Start range Dropdown -->
        <b-form-group
          label-for="pay-cycle-config-session"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <!-- <v-select
            v-model="searchForm.startRange"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select End Range"
            :options="endRange"
            :reduce="(val) => val.id"
          /> -->
          <b-form-datepicker
            v-model="searchForm.startRange"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            placeholder="DD/MM/YYYY"
            reset-button
          />
        </b-form-group>
        <b-form-group
          class="pr-0 col-lg-1 col-md-1 col-sm-6 text-mt"
        >Between
        </b-form-group>

        <!-- End range Dropdown -->
        <b-form-group
          label-for="endRange"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <b-form-datepicker
            v-model="searchForm.endRange"
            placeholder="DD/MM/YYYY"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            reset-button
          />
        </b-form-group>

        <!-- Calculate Dropdown -->
        <b-form-group
          class="pr-0 col-lg-1 col-md-1 col-sm-6 text-mt"
        >Calculate
        </b-form-group>
        <b-form-group
          label-for="calculate"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.calculateType"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select calculate"
            :options="calculate"
            :reduce="(val) => val.id"
          />
        </b-form-group>
        <!-- salary Dropdown -->
        <b-form-group
          class="pr-0 col-lg-1 col-md-1 col-sm-6 text-mt"
        >Salary
        </b-form-group>
        <b-form-group
          label-for="salary"
          class="pr-0 col-lg-1 col-md-1 col-sm-6"
        >
          <b-form-input
            v-model="searchForm.salary"
            placeholder="Salary"
            type="number"
          />
        </b-form-group>

        <!-- Mode Dropdown -->
        <b-form-group
          label-for="method"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <v-select
            v-model="searchForm.paymentMethodType"
            :disabled="isLoading"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Method"
            :options="paymentMethod"
            :reduce="(val) => val.id"
          />
        </b-form-group>
      </div>
      <div class="row justify-content-center">
        <!-- Search Dropdown -->
        <b-form-group
          label-for="calculate"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <b-button
            variant="outline-success"
            :disabled="isLoading"
            @click="onSearch"
          >
            Search
          </b-button>
        </b-form-group>
        <!-- Clear Dropdown -->
        <b-form-group
          label-for="calculate"
          class="pr-0 col-lg-2 col-md-2 col-sm-6"
        >
          <b-button
            variant="outline-danger"
            :disabled="isLoading"
            @click="onClear"
          >
            Clear
          </b-button>
        </b-form-group>
      </div>

      <!-- Text and Gridview  -->
      <div v-if="(rows.length || demoEmployeeData.length) && !isLoading">
        <!-- table for payment cycle  -->
        <vue-good-table
          :columns="columns"
          :rows="demoEmployeeData"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :line-numbers="true"
          theme="my-theme"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: payment -->
            <span v-if="props.column.field === 'payment'">
              <!-- <span class="d-flex"> -->
              <b-form-group :state="paymentInput">
                <b-form-input
                  v-model="payment"
                  placeholder="Payment"
                  :state="paymentInput"
                  required
                />
              </b-form-group>
              <!-- </span> -->
            </span>
            <!-- Column: payment mode -->
            <span v-if="props.column.field === 'paymentMode'">
              <span class="d-flex">
                <v-select
                  v-model="paymentMethodType"
                  :disabled="isLoading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="Select Method"
                  :options="paymentMethod"
                  :reduce="(val) => val.id"
                  class="w-100"
                />
              </span>
            </span>
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span class="d-flex">
                <b-button
                  class="mr-1 px-1"
                  variant="outline-primary"
                  :disabled="isLoading"
                  @click="onPay(props.row)"
                >Pay</b-button>
                <b-button
                  class="px-1 mr-1"
                  variant="outline-success"
                  :disabled="isLoading"
                  @click="onEdit(props.row)"
                >Edit</b-button>
                <b-button
                  class="px-1 mr-1"
                  variant="outline-danger"
                  :disabled="isLoading"
                  @click="onDelete(props.row)"
                >Delete</b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!--  pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <Pagination
              :v-if="props"
              :pagination-props="props"
            />
          </template>
        </vue-good-table>
      </div>
      <preloader v-if="isLoading" />
      <!-- <PaymentModal :modal-show="modalShow" /> -->

      <!-- payment Modal -->
      <b-modal
        id="employee-salary-modal"
        title="Employee Salary Details"
        hide-footer
        size="lg"
      >
        <!-- {{ JSON.stringify(paymentModalData) }} -->

        <div class="row justify-content-center">
          <table class="table">
            <tr>
              <td>Employee ID</td>
              <td>{{ paymentModalData.employeeId }}</td>
            </tr>
            <tr>
              <td>Employee Name</td>
              <td>
                {{ paymentModalData.employee_name }} <br>
                {{
                  paymentModalData.designation +
                    ", " +
                    paymentModalData.depertment +
                    ", " +
                    paymentModalData.campus
                }}
              </td>
            </tr>
            <tr>
              <td>Payslip Month</td>
              <td>{{ paymentModalData.paysliipMonth }}</td>
            </tr>
            <tr>
              <td>Gross Salary</td>
              <td>{{ paymentModalData.gross_salary }}</td>
            </tr>
            <tr>
              <td>Net Payable</td>
              <td>{{ paymentModalData.net_payable }}</td>
            </tr>
          </table>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>SL</th>
              <th>Paid Amount</th>
              <th>Due Amount</th>
              <th>Mode</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>10000</td>
              <td>17000</td>
              <td>Bank</td>
              <td>10-07-2021 10:20 AM</td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-center">
          <b-button
            variant="outline-success text-center"
            @click="onConfirmPayment"
          >
            Confirm Payment
          </b-button>
        </div>
      </b-modal>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  // BPagination,
  //   BForm,
  BFormGroup,
  // BFormSelect,
  //   BFormCheckbox,
  // BFormCheckboxGroup,
  VBModal,
  BButton,
  //   BRow,
  //   BCol,
  BFormDatepicker,
  BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import { VueAutosuggest } from 'vue-autosuggest'
import Pagination from '@/views/components/vue-good-table-pagination/Pagination.vue'
// import flatPickr from 'vue-flatpickr-component'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import Preloader from '../components/preloader/preloader.vue'
// import PaymentModal from './components/PaymentModal.vue'

// import { RefreshCwIcon } from 'vue-feather-icons'

export default {
  components: {
    BCard,
    VueGoodTable,
    // BPagination,
    // BForm,
    BFormInput,
    BFormGroup,
    // BFormSelect,
    // BFormCheckbox,
    vSelect,
    BButton,
    // BRow,
    // BCol,
    // BFormTextarea,
    // BTable,
    // flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // BFormRadioGroup,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // draggable,
    // Invoice,
    // RefreshCwIcon,
    Preloader,
    Pagination,
    // PaymentModal,
    BFormDatepicker,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      props: {},
      paymentInput: null,
      is_show: false,
      checkboxStatus: 'not_checked',
      isFeeReason: false,
      /*
       * variable for adj
       */

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Employee ID',
          field: 'employeeId',
          width: '150px',
        },
        {
          label: 'Employee Name',
          field: 'employee_name',
          width: '200px',
        },
        {
          label: 'Campus',
          field: 'campus',
        },
        {
          label: 'Depertment',
          field: 'depertment',
        },
        {
          label: 'Gross Salary',
          field: 'gross_salary',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '150px',
        },
        {
          label: 'Others Allowance',
          field: 'others_allownce',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '200px',
        },
        {
          label: 'Salary Adjustment',
          field: 'salary_adjustment',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '200px',
        },
        {
          label: 'Loan Deduction',
          field: 'loan_deduction',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '200px',
        },
        {
          label: 'Advance Salary Deduction',
          field: 'advance_salary_deduction',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '300px',
        },
        {
          label: 'Others Deduction',
          field: 'others_deduction',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '200px',
        },
        {
          label: 'Tax Deduction',
          field: 'tax_deduction',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '180px',
        },
        {
          label: 'Net Salary',
          field: 'net_salary',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '150px',
        },
        {
          label: 'Paid Amount',
          field: 'paid_amount',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '150px',
        },
        {
          label: 'Net Payable',
          field: 'net_payable',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
          width: '150px',
        },
        {
          label: 'Payment',
          field: 'payment',
          width: '150px',
        },
        {
          label: 'Payment Mode',
          field: 'paymentMode',
          width: '200px',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      tempRows: [],

      type: 1,
      sessionTypes: [
        { id: 1, name: '2018-2019' },
        { id: 2, name: '2019-2020' },
      ],
      paySlipMonths: [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
      ],

      campuses: [
        { id: 1, name: 'MC' },
        { id: 2, name: 'BC' },
      ],

      employeeTypes: [
        { id: 1, name: 'Type one ' },
        { id: 2, name: 'TypeTow' },
      ],

      departments: [
        { id: 1, name: 'ICT ' },
        { id: 2, name: 'Education' },
      ],

      designations: [
        { id: 1, name: 'Software Engineer ' },
        { id: 2, name: 'Product Manager' },
      ],
      startRange: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
      ],
      endRange: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
      ],
      calculate: [
        { id: 1, name: 'Fixed ' },
        { id: 2, name: 'Percentage' },
      ],
      paymentMethod: [
        { id: 1, name: 'Bank ' },
        { id: 2, name: 'Cash' },
      ],
      searchForm: {
        sessionId: '',
        paySlipMonthId: '',
        campusId: '',
        employeeTypeId: '',
        departmentId: '',
        designationId: '',
        startRange: '',
        endRange: '',
        calculateType: '',
        paymentMethodType: '',
      },
      paymentMethodType: '',
      payment: '17000',
      isLoading: false,
      modalShow: false,
      paymentModalData: {},

      demoEmployeeData: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {},
  created() {
    setTimeout(() => {}, 500)
  },
  mounted() {},
  methods: {
    // search data
    onSearch() {
      this.isLoading = true
      console.log(this.searchForm)
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    // on clear
    onClear() {
      this.searchForm = {}
    },
    onGridData() {},
    // on pay grid data
    onPay(row) {
      this.$bvModal.show('employee-salary-modal')
      console.log(`on pay${row}`)
      this.paymentModalData = row
      // this.modalShow = true
    },

    // on edit data grid data
    onEdit(row) {
      console.log(`on edit${row}`)
    },

    // on delete grid data
    onDelete(row) {
      console.log(`on delete${row}`)
    },

    // on configm payment
    onConfirmPayment() {
      console.log('ok')
      FemsToastrService.success('Only for demo Toastr!')
    },
  },
  /*
   * custom payment
   */
}
</script>
<style lang="scss">
.text-mt {
  margin-top: 8px;
}
</style>
